export const BASE_URL = 'https://qwark-api-test.kuarasoftware.es/';
// export const BASE_URL = 'http://localhost:3005/';

export const API = {
  PROFILE: 'user/profile',
  EXPENSE: 'expense',
  BUSINESS: 'business',
  USER: 'users',
  TAG: 'tag',
  PROJECT: 'project',
  TASK: 'task',
  PROYECT: 'proyect',
  EXPENSE_SHEET: 'expensesheet',
  PAYMENT_METHOD: 'paymentmethod',
  CURRENCY_CODE: 'currencycode',
  SIGN_UP: 'singup',
  HOLIDAY: 'holiday',
  TRAVEL: 'travel',
  ESTABLISHMENT: 'establishment',
  ESTABLISHMENT_INFO: 'establishmentinfo',
  ESTABLISHMENT_SETTINGS: 'establishment/settings',
  ROUTE: 'route',
  SUPERVISOR: 'supervisor',
  COUNTRIES: 'bc/countries',
  GROUP: 'group/user',
  COMPANY: 'company',
  PROVIDER: 'provider',
  CUSTOM_FIELD: 'customField',
  CUSTOM_OBJECT: 'customObject',
  CUSTOM_OBJECT_TYPE: 'customObjectType',
};

export const GOOGLE_API_KEY = 'AIzaSyD50LD-Kao0Edgh29loIw_JFzv065ZP11k';

export const EXPENSE_TYPES = {
  TICKET: 'Ticket',
  INVOICE: 'Factura',
  MILEAGE: 'Kilometraje',
  DIET: 'Dieta',
};

export const ROLES = {
  S_ADMIN: '8fc02de6',
  ADMIN: 'a2470ee4',
  USER: '97f567cf',
};

export const STATES = {
  CANCELED: {
    label: 'Cancelado',
    color: '#641E16',
  },
  REJECTED: {
    label: 'Denegado',
    color: '#E74C3C',
  },
  ACCEPTED: {
    label: 'Aceptado',
    color: '#27AE60',
  },
  PENDING: {
    label: 'Pendiente',
    color: '#2980B9',
  },
};
